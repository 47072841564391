import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  
  state: {
    init: false,                                            // proxy session and token control
    engineInit: false,                                      // engine session and ident control
    categoriesInit: false,                                  // have category file been loaded
    token: sessionStorage.token ? sessionStorage.token : '',    // proxy token
    ident: '',                                              // ident
    answerId: '',                                           // answer id, used for dtree nav purposes as dtree requests are separated from the normal conversation flow
    isAjaxDone: true,                                       // is there an active Ajax request in progress
    isAutocompleteAjaxDone: true,
    responseData: {},                                       // engine response object
    autocompleteResponseData: null,                         // autocomplete data, gets updated on each autocomplete request
    categories: [],                                         // static category structure, loaded from a JSON file
    searchStatus: false,                                    // is main menu search field active
    mobMenuStatus: false,                                   // is mobile menu active
    catDestination: '',                                     // which category I'm going to. VP cat nav is case sensitive so we need exact string from category JSON file
    customPages: [
      {
        path: '/service-unavailable',
        query: ['service unavailable']
      },
      {
        path: '/article/service-status',
        query: ['service status']
      },
    ],

    faqDestinationId: '',
    //dtreeDestinationCat: '',

    survey: false,

    /*
    staticData: {
      icsNotHelpful: '394',
      icsHelpful: '393',
    },
    */
    error: {
      show: false,
      reason: '',
      code: 0,
      message: '',
      retries: 0
    }
  },

  mutations: {

    initialised(state, init) {
        state.init = init;
    },

    initialisedEngine(state) {
      state.engineInit = true;
    },

    initialisedCategories(state) {
      state.categoriesInit = true;
    },

    setToken(state, token) {
      state.token = token;
    },

    setIdent(state, ident) {
      state.ident = ident;
    },

    setAnswerId(state, answerId) {
      state.answerId = answerId;
    },

    setCatDestination(state, catDestination) {
      state.catDestination = catDestination;
    },

    setFaqDestinationId(state, faqDestinationId) {
      state.faqDestinationId = faqDestinationId;
    },

    setDtreeDestinationCat(state, dtreeDestinationCat) {
      state.dtreeDestinationCat = dtreeDestinationCat;
    },

    setAjaxFlag(state, flag) {
      state.isAjaxDone = flag;
    },

    setAutocompleteAjaxFlag(state, flag) {
      state.isAutocompleteAjaxDone = flag;
    },

    setResponseData(state, data) {
      state.responseData = data;
    },

    setCategoryData(state, data) {
      state.categories = data;
    },

    setAutocompleteResponseData(state, data) {
      state.autocompleteResponseData = data;
    },  

    setSearchStatus(state, flag) {
      state.searchStatus = flag;
    },

    setMobMenuStatus(state, flag) {
      state.mobMenuStatus = flag;
    },

    setError(state, data) {
      state.error = data;
    },

    setSurveyState(state, data) {
      state.survey = data;
    },

  },








  actions: {
  
    initialised({commit}, init) {
      commit('initialised', init);
    },

    initialisedEngine({commit}) {
      commit('initialisedEngine')  
    },

    initialisedCategories({commit}) {
      commit('initialisedCategories')  
    },

    setToken({commit}, token) {
      commit('setToken', token);
    },

    setIdent({commit}, ident) {
      commit('setIdent', ident);
    },

    setAnswerId({commit}, answerId) {
      commit('setAnswerId', answerId);
    },

    setCatDestination({commit}, catDestination) {
      commit('setCatDestination', catDestination);
    },

    setFaqDestinationId({commit}, faqDestinationId) {
      commit('setFaqDestinationId', faqDestinationId);
    },

    /*
    setDtreeDestinationId({commit}, dtreeDestinationId) {
      commit('setDtreeDestinationId', dtreeDestinationId);
    },
    */

    setAjaxFlag({commit}, flag) {
      commit('setAjaxFlag', flag);
    },

    setAutocompleteAjaxFlag({commit}, flag) {
      commit('setAutocompleteAjaxFlag', flag);
    },

    setResponseData({commit}, data) {
      commit('setResponseData', data);
    },

    setAutocompleteResponseData({commit}, data) {
      commit('setAutocompleteResponseData', data);
    },

    setCategoryData({commit}, data) {
      commit('setCategoryData', data);
    },

    setSearchStatus({commit}, data) {
      commit('setSearchStatus', data);
    },

    setMobMenuStatus({commit}, data) {
      commit('setMobMenuStatus', data);
    },

    setError({commit}, data) {
      commit('setError', data);
    },

    setSurveyState({commit}, data) {
      commit('setSurveyState', data);
    },

  }

});

export default store;