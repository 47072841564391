<template>
    <div class="main-header">
        <div class="wrapper">
            <a href="/" class="main-logo" title="Lloyds Bank" @click.prevent="goHome">Lloyds Bank</a>
            <div class="logonwrapper">
                <a href="https://cbonline.lloydsbank.com/PrimaryAuth/" class="login" target="_blank" rel="noopener" title="Log on">Log on</a>
            </div>
            <h1>Commercial Banking Online <span>Help Centre</span></h1>
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'MainHeader',

    computed: {
        currentRoute() {
            return this.$route;
        },

        customPages() {
            return store.state.customPages;
        },

        isCustomPage() {
            let found = false;
            let route = this.currentRoute;
            let pages = this.customPages;

            for(var key in pages) {
                if((route.path == '/search' && pages[key].query == route.query.q) || pages[key].path == route.path) {
                found = true;
                break;
                }
            }
            return found;
        }
    },

    watch: {
        currentRoute(n) {
            return n;
        },

        customPages(n) {
            return n;
        },

        isCustomPage(n) {
            return n;
        }
    },

    methods: {
        goHome() {
            if(this.isCustomPage) {
                return false;
            }
            else {
                router.push({ path: '/'});
            }
        }
    }
}

</script>

<style scoped>
    
    .main-header {
        background: var(--everyday-green);
    }

    .wrapper {
        height: 96px;
    }

    h1 {
        text-transform: uppercase;
        font-size: 1em;
        position: absolute;
        right: 130px;
        width: 310px;
        text-align: right;
        top: 34px;
        margin: 0;
        font-weight: 500;
        letter-spacing: 2.02px;
        padding: 0;
        line-height: 14px;
    }

    h1 span {
        /*font-family: 'GT Ultra Standard Lloyds Regular', Arial, Helvetica, sans-serif;*/
    }

    .main-logo {
        display: block;
        text-decoration: none;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/LLOYDS_BANK_CBOHelpCentre.png');
        /*background-position: 0 0;*/
        /*background-size: 300px 300px;*/
        width: 210px;
        height: 52px;
        top: 0;
        bottom:0;
        margin: auto;
        position: absolute;
        background-size: contain;
    }

    .logonwrapper {
        height: 36px;
        width: 116px;
        position: absolute;        
        right: -8px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100%;
        transition: all .5s ease-out;
        padding: 24px;
    }

    .logonwrapper:hover {
        background-color: #ffffff;
    }

    .login {
        text-decoration: none;
        display: block;
        padding: 0 0 0 0px;
        font-family: 'GT Ultra Standard Lloyds Regular', Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 52px;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
        top: 6px;
        position: relative;
        left: 18px;
    }

    .login:hover {
        /*background: #0D9362;*/
        /*text-decoration: underline;*/
    }

    .login:before {
        content: '';
        display: block;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url('./../assets/images/padlock.svg');;
        /* background-position: -50px -150px; */
        /* background-size: 300px 300px; */
        width: 24px;
        /* height: 36px; */
        top: 11px;
        bottom: 0;
        /* left: 15px; */
        margin: auto;
        position: absolute;
        background-size: contain;
        left: -28px;
    }

    .login:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 5px;
        width: 5px;
        transform: translateY(-50%) rotate(45deg);
        color: #ffffff;
        margin: 0 0 -4px 8px;
        display:none;
    }

    @media only screen and (max-width: 966px) {
        .main-header {
            padding: 0 18px 0 18px;
        }
    }

    @media only screen and (max-width: 650px) {
        .wrapper {
            height: 72px;
        }

        .main-logo {
            background-position: -157px 0;
            width: 53px
        }

        h1 {
            top: 22px;
            right:200px;
        }

        .login {
            right: 70px;
        }

    }

    @media only screen and (max-width: 500px) {
        h1 {
            top: 22px;
            right: 200px;
            font-size: .5em;
            width: 140px;      
        }
    }

    @media only screen and (max-width: 430px) {
        h1 {
            width: 300px;
            right: 70px;
            top: 56px;
        }
    }

    @media only screen and (max-width: 410px) {
        h1 {
            display: none;
        }
    }
    
</style>
