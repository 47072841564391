<template>
    <div class="main-menu-search" :class="{active: isSearchActive}">
        <div class="search-field">
            <div class="va-inp">
                <div class="va-inp-in">
                    <div class="va-txt-pod">
                        <input type="text" placeholder="Search..." class="va-inp-txt main-menu-search-field" id="entry" name="entry" tabindex="0" autocomplete="off" v-on:keyup.13="submitQuestion" @input="submitAutocompleteQuestion" v-on:keyup.down="nextItem" v-on:keyup.up="prevItem" v-model="question" data-tealium-id="menusearch" data-tealium-tracking="none" ref="main-input" aria-label="Please enter your search query"/>
                    </div>
                    <div class="va-btn-pod">
                        <button type="submit" name="send" class="va-inp-btn" tabindex="0" @click.prevent="submitQuestion" data-tealium-tracking="full" data-tealium-value="$menusearch" data-tealium-narrative="Main Menu Search" :class="{activecta: question.length > 0}">Search</button>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="this.question != '' && this.showAutocomplete">
            <main-menu-search-field-autocomplete :key="'s1'" :query="question" :autocomplete="adata" :count="counter" :origin="componentOrigin"></main-menu-search-field-autocomplete>
        </template>

    </div>
</template>

<script>

import {Event} from '../event';
import store from '../store';
import router from '../router';

import MainMenuSearchFieldAutocomplete from '@/components/MainMenuSearchFieldAutocomplete';

export default {
    name: 'MainMenuSearchField',
    components: {
        MainMenuSearchFieldAutocomplete
    },
    data() {
        return {
            question: '',
            timer: null,
            interval: null,
            counter: -1,
            componentOrigin: "mainmenu",
            debug: true,
            adata: {},
            showAutocomplete: false
        }
    },

    beforeRouteLeave(to, from, next) {
        this.question = '';
        next();
    },

    methods: {
        resetAutocomplete: function() {
            this.counter = -1;
            this.showAutocomplete = false;
            store.dispatch('setAutocompleteResponseData', null);
        },

        submitQuestion: function() {
            if(!this.question) {
                return false;
            }
            else {
                let entry = this.question;
                this.question = '';
                if(this.counter != -1) {
                    entry = this.autocompleteData.AutoComplete[this.counter].QuestionText;
                }

                this.resetAutocomplete();
                if(!this.isAutocompleteRequestFinished) {
                    this.debug && console.log('[AUTOCOMPLETE] Autocomplete request not finished, setting interval to wait');
                    this.interval = setInterval(() => {
                        if(this.isAutocompleteRequestFinished) {
                            this.debug && console.log('[AUTOCOMPLETE] Autocomplete request finished, submitting normal request');
                            router.push({ path: '/search', query: {q: entry}});
                            clearInterval(this.interval);
                            this.interval = null;
                        }
                    }, 100);
                }
                else {
                    router.push({ path: '/search', query: {q: entry}});
                }
            }
        },

        submitAutocompleteQuestion: function() {
            if(this.isAutocompleteRequestFinished) {
                if(this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    if(this.question.length > 2) {
                        //Event.$emit('va.autocomplete', {entry: this.question});
                        Event.$emit('va.request', {type: 'autocomplete', question: this.question});
                        this.showAutocomplete = true;
                    }
                    else {
                        this.resetAutocomplete(); 
                    }
                }, 100);
            }
        },

        nextItem: function(event) {
            if(this.counter < this.autocompleteData.AutoComplete.length - 1) {
                this.counter++;
            }
            else {
                this.counter = 0;
            }
        },

        prevItem: function(event) {
            if(this.counter < this.autocompleteData.AutoComplete.length && this.counter != -1 && this.counter != 0) {
                this.counter--;
            }
            else {
                this.counter = this.autocompleteData.AutoComplete.length - 1;    
            }
        },

        handleClickOutside: function(e) {
            if(!document.getElementsByClassName('va-inp-in')[0].contains(e.target)) {
                this.resetAutocomplete();
            }
        },

        handleEscPress: function(e) {
            if(e.keyCode == 27) {
                this.resetAutocomplete();
            }
        }
    },

    computed: {
        isSearchActive() {
            return store.state.searchStatus;
        },

        autocompleteData() {
            return store.state.autocompleteResponseData;
        },

        isAutocompleteRequestFinished() {
            return store.state.isAutocompleteAjaxDone;
        },
    },

    watch: {
        isSearchActive(n) {
            n && this.$refs['main-input'].focus();
        },

        autocompleteData(n) {
            this.adata = n;
        }
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keyup', this.handleEscPress);
    },

    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('keyup', this.handleEscPress);
    },

    beforeRouteLeave(to, from, next) {
        this.question = '';
        this.counter = -1;
        next();
    },
}
</script>

<style scoped>

    .main-menu-search {
        height: 0;
        top: 48px;
        background: var(--everyday-green);
        z-index: 10;
        left: 0;
        right: 0;
        /*position: absolute;*/
        transition: opacity .2s, height .3s;
        opacity: 0;
    }

    .main-menu-search.active {
        height: 75px;
        opacity: 1;
        /*box-shadow: inset 0 2px 9px 0 rgba(50,50,51,0.54);*/
        box-shadow: inset 0 6px 9px -4px rgba(50,50,51,0.54);
    }

    .search-field {
        max-width: 930px;
        margin: 0 auto;
    }

    .va-inp {
        padding-top: 14px;
    }

    .va-inp * {
        font-family: 'GT Ultra Standard Lloyds Regular', Arial, Helvetica, sans-serif;
    }

    .va-inp-in {
        position: relative;
    }

    /*
    .va-inp-in:focus {
    }
    */

    .va-txt-pod {
        margin-right: 140px;
        position: relative;
    }

    .va-inp-txt {
        border: none;
        line-height: 48px;
        background: #FFFFFF;
        height: 48px;
        outline: none;
        color: #000000;
        box-shadow: none;
        width: 100%;
        padding: 0 17px 0 17px;
        font-size: 1.125rem;
        border-radius: calc(1rem*0.4);
    }

    ::placeholder {
        color: #ffffff;
    }

    .va-inp-txt:focus {
        font-family: 'GT Ultra Standard Lloyds Regular', Arial, Helvetica, sans-serif;
    }

    .va-btn-pod {
        position:absolute;
        right:0;
        top:0;
    }

    .va-inp-btn {
        display: inline-block;
        width: 88px;
        height: 48px;
        background: #000000;
        color: #FFFFFF;
        text-align: left;
        text-decoration: none;
        line-height: 48px;
        transition: all 0.5s;
        /*border: 1px solid #BFBFBF;*/
        border: none;
        /*box-shadow: inset 0px 0px 0px 1px #bfbfbf;*/
        position: relative;
        /*padding: 0 0 0 18px;*/
        text-align: center;
        padding: 0;
        font-size: 1.125em;
        border-radius: var(--border-default);
        border: solid 1px #000000;
    }

    /*
    .va-inp-btn:after {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: block;
        height: 5px;
        right: 15px;
        position: absolute;
        width: 5px;
        top: 54%;
        margin: auto;
        transform: translateY(-50%) rotate(45deg);
        color: #BFBFBF;
    }
    */

    .va-inp-btn:hover, .va-inp-btn.activecta {
        cursor: pointer;
        box-shadow: inset 0px 0px 0px 2px #ffffff;
        color: #000000;
        background: #ffffff;
        border: solid 1px #000000;
        text-decoration: underline 2px;

        /*
        box-shadow: inset 0 6px 9px -4px rgba(50,50,51,0.54);
        */
    }

    /*
    .va-inp-btn:hover:after {
        color: #ffffff;
    } 
    */ 

</style>
