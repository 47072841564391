<template>
    <div class="panel">
        <h4>In this section</h4>
        <ul>
            <li v-for="(item, index) in faqs" :key="index">
                <a :href="'/' + item.path + '/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-answerid="item.answer_id" @click.prevent="submitRequest(item)">{{item.faqdisplay}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'ViewCategoryFaqPanel0',
    computed: {
        faqs: function() {
            return store.state.responseData.data.questions;
        },

        faqList: function() {
            let arr = this.faqs;
            let half = Math.floor(arr.length / 2)
            let first = arr.slice(0, half);
            //let second = arr.slice(half, arr.length);           
            return first;
        },
    },
    methods: {
        submitRequest: function(faq) {
            let title = faq.faqdisplay;
            title = title.toLowerCase();
            title = title.replace(/\ /g, '-');
            title = title.replace(/\?/gi, '');

            store.dispatch('setFaqDestinationId', faq.answer_id);
            router.push({path: '/' + faq.path + '/article/' + title});
        },

        titleToLink(title) {
            let link = title;
            link = link.toLowerCase();
            link = link.replace(/ /g, '-');
            link = link.replace(/\?/gi, '');
            return link;
        }
    },
}
</script>

<style scoped>
    .panel {
        width: 100%;
        padding: 30px;
        margin: 0 0 30px 0;
        /*box-shadow: inset 0 4px 0 0 var(--everyday-green), 0 0 5px 0 rgba(173,173,173,0.5);*/
        border-radius: var(--border-medium);
        background-color: var(--everyday-green);
        color: #000000;
    }

    .panel h4 {
        font-size: 1.5em;
        padding: 0 0 15px 0;
        margin: 0 0 15px 0;
        font-weight: normal;
    }

    .panel ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.125em;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .panel ul li {
        width: calc(50% - 16px);
    }

    .panel ul li:nth-child(2n+1) {
        margin-right: 16px;
    }

    .panel ul li a {
        padding: 0 0 10px 0;
        display: block;
        position: relative;
    }

    .panel ul li a:after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url('./../assets/images/ChevronRight.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 0 -1.5px 4px;
    }

    @media only screen and (max-width: 650px) {
        .panel {
            width: 100%;
        }

        .panel ul li {
            width: 100%;
        }
    }
</style>
