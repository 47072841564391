import { render, staticRenderFns } from "./MainPageMaincatSelector.vue?vue&type=template&id=67b798ce&scoped=true&"
import script from "./MainPageMaincatSelector.vue?vue&type=script&lang=js&"
export * from "./MainPageMaincatSelector.vue?vue&type=script&lang=js&"
import style0 from "./MainPageMaincatSelector.vue?vue&type=style&index=0&id=67b798ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b798ce",
  null
  
)

export default component.exports