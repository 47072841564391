<template>
    <div class="faq-selector" v-if="faqs.length > 0" :class="{visuallyhidden: this.isCustomPage}">
        <div class="wrapper">
            <h4>Related topics</h4>
            <ul>
                <li v-for="(item, index) in this.randomiseList" :key="index">
                    <template v-if="item.path.length > 0">
                        <a :href="'/' + item.path + '/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-answerid="item.answer_id" @click.prevent="submitRequest(item)">{{ item.faqdisplay }}</a>
                    </template>
                    <template v-else>
                        <a :href="'/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-answerid="item.answer_id" @click.prevent="submitRequest(item)">{{ item.faqdisplay }}</a>
                    </template>

                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import router from '../router';
import store from '../store';

export default {
    name: 'AnswerPageFaqSelector',
    props: ['faqs'],
    data() {
        return {
            limit: 20
        }
    },
    methods: {
        submitRequest: function(faq) {
            let title = faq.faqdisplay;
            title = title.toLowerCase();
            title = title.replace(/\ /g, '-');
            title = title.replace(/\?/gi, '');

            store.dispatch('setFaqDestinationId', faq.answer_id);

            if(faq.path.length > 0) {
                router.push({path: '/' + faq.path + '/article/' + title});
            }
            else {
                router.push({path: '/article/' + title});
            }
        },

        titleToLink(title) {
            let link = title;
            link = link.toLowerCase();
            link = link.replace(/ /g, '-');
            link = link.replace(/\?/gi, '');
            return link;
        }
    },
    computed: {
        randomiseList() {
            let s = this.faqs.slice().sort(() => 0.5 - Math.random());
            return s.slice(0, this.limit);
        },

        currentRoute() {
            return this.$route;
        },

        customPages() {
            return store.state.customPages;
        },

        isCustomPage() {
            let found = false;
            let route = this.currentRoute;
            let pages = this.customPages;

            for(var key in pages) {
                if((route.path == '/search' && pages[key].query == route.query.q) || pages[key].path == route.path) {
                found = true;
                break;
                }
            }
            return found;
        }
    },

    watch: {
        currentRoute(n) {
            return n;
        },

        customPages(n) {
            return n;
        },

        isCustomPage(n) {
            return n;
        }
    }
}
</script>

<style scoped>
    .faq-selector {
        background: var(--everyday-green);
        padding: 24px;
        border-radius: var(--border-medium);
    }


    h4 {
        font-size: 1.5em;
        font-weight: normal;
        margin: 0;
        padding: 0;
    }

    .faq-selector ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .faq-selector ul li {
        width: calc(50% - 16px);
        padding: 24px 0 0 0;
    }

    .faq-selector ul li:nth-child(2n+1) {
        margin-right: 16px;
    }

    .faq-selector a {
        position: relative;
        font-size: 1em;
        font-family: 'GT Ultra Standard Lloyds Regular', Arial, Helvetica, sans-serif;
        font-weight: 500;
    }

    .faq-selector a:after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url('./../assets/images/ChevronRight.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 0 -2.5px 2px;
    }

    @media only screen and (max-width: 650px) {
        .faq-selector ul li {
            width: 100%;
        }
    }

</style>
