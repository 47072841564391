<template>
    <div class="va-category">
        <div class="wrapper">
            <template v-if="init && engineInit && categoryInit && isRequestFinished">
                <single-page-notification :notification="response.data.etv.notification"></single-page-notification>
                <single-page-breadcrumbs></single-page-breadcrumbs>
                <h2>{{this.getCurrentCategoryName}}</h2>

                <template v-if="typeof cat3 != 'undefined' || !hasChildCategories">
                    <view-category-page-description></view-category-page-description>
                    <div class="category-faq-panels">
                        <view-category-faq-panel-0></view-category-faq-panel-0>
                        <!--<view-category-faq-panel-1></view-category-faq-panel-1>-->
                    </div>
                    <!--<view-category-frequent-questions :faqs="response.data.questions"></view-category-frequent-questions>-->
                </template>

                <template v-else-if="typeof cat2 != 'undefined' || !hasChildCategories">
                    <div class="category-panels">
                        <template v-for="(items, index) in getPanelData(2)">
                            <!--<component :is="getPanelComponent(index)" :key="index" v-bind:catdata="items"></component>-->
                            <view-category-page-panel :key="index" :catdata="items" :view="index" :parent="currentCategory"></view-category-page-panel>
                        </template>
                    </div>
                    <!--<main-page-maincat-selector :clean="true"></main-page-maincat-selector>-->
                    <!--<view-category-page-related-topics :faqs="response.data.questions"></view-category-page-related-topics>-->
                </template>

                <template v-else-if="typeof cat1 != 'undefined' || !hasChildCategories">
                    <div class="category-panels">
                        <template v-for="(items, index) in getPanelData(1)">
                            <!--<component :is="getPanelComponent(index)" :key="index" :catdata="items"></component>-->
                            <view-category-page-panel :key="index" :catdata="items" :view="index" :parent="currentCategory"></view-category-page-panel>
                        </template>
                    </div>
                    <!--<main-page-maincat-selector :clean="true"></main-page-maincat-selector>-->
                    <!--<view-category-page-related-topics :faqs="response.data.questions"></view-category-page-related-topics>-->
                </template>

                <single-page-footer></single-page-footer>
            </template>

            <template v-else>
                <main-ajax-spinner></main-ajax-spinner>
            </template>
            <!--<cookie-policy></cookie-policy>-->
        </div>
    </div>
</template>

<script>
import {Event} from "../event";
import store from '../store';
import router from '../router';
import tealium from '../mixins/tealium.js';

import ViewCategoryPagePanel from '@/components/ViewCategoryPagePanel';
import ViewCategoryPageDescription from '@/components/ViewCategoryPageDescription';
//import MainPageMaincatSelector from '@/components/MainPageMaincatSelector';
import SinglePageBreadcrumbs from '@/components/SinglePageBreadcrumbs';
import SinglePageFooter from '@/components/SinglePageFooter';
import MainAjaxSpinner from '@/components/MainAjaxSpinner';
import ViewCategoryFaqPanel0 from '@/components/ViewCategoryFaqPanel0';
//import ViewCategoryFaqPanel1 from '@/components/ViewCategoryFaqPanel1';
//import ViewCategoryFrequentQuestions from '@/components/ViewCategoryFrequentQuestions';
//import ViewCategoryPageRelatedTopics from '@/components/ViewCategoryPageRelatedTopics';
import SinglePageNotification from '@/components/SinglePageNotification';
//import CookiePolicy from '@/components/CookiePolicy';
 


export default {
    name: 'ViewCategoryPage',
    props: ['cat1', 'cat2', 'cat3'],
    components: {
        ViewCategoryPagePanel,
        ViewCategoryPageDescription,
        //MainPageMaincatSelector,
        SinglePageBreadcrumbs,
        SinglePageFooter,
        MainAjaxSpinner,
        ViewCategoryFaqPanel0,
        //ViewCategoryFaqPanel1,
        //ViewCategoryFrequentQuestions,
        //ViewCategoryPageRelatedTopics,
        SinglePageNotification,
        //CookiePolicy
    },
    mixins: [tealium],
    data() {
        return {
            currentCategory: '',
            currentCategry: '',
            debug: false,
            hash:''
        }
    },

    updated () {
       this.scrollToHash()
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        next(vm => {
            window.scrollTo(0, 0);
            vm.tealiumRouteTracking(to);
            if(vm.hash == '')
                vm.hash = to.hash
        });
    },

    beforeRouteUpdate(to, from, next) {
        store.dispatch('setSearchStatus', false); // reset search status
        window.scrollTo(0, 0);
        this.tealiumRouteTracking(to);
        next();
    },

    computed: {
        categories() {
            return store.state.categories;
        },

        init() {
            return store.state.init;
        },

        categoryInit() {
            return store.state.categoriesInit;
        },

        engineInit() {
            return store.state.engineInit;
        },

        response () {
            return store.state.responseData;
        },

        getCurrentCategoryName() {
            let name = store.state.responseData.data.maincat.split('.');
            //this.currentCategory = name[name.length - 1];
            return name[name.length - 1];
        },

        hasChildCategories() {
            // if dropdown node is empty, we assume that there are no subcategories and we will display the according view 
            if(store.state.responseData.data.dropdown.length > 0) {
                return true;
            }
            else {
                return false;
            }
        },

        isRequestFinished() {
            return store.state.isAjaxDone;
        },

        token() {
            return store.state.token;
        },
    },

    created() {
        this.debug && console.log('[CATEGORY_PATH] %s %s %s', this.cat1, this.cat2, this.cat3);

        if(this.isSessionValid() && this.categoryInit) {
            this.debug && console.log('e02');
            if(store.state.catDestination !== '') { // this is a Back nav check
                this.debug && console.log('e02-1');
                Event.$emit('va.request', {type: 'catnav', category: store.state.catDestination});
            }
            else {
                this.debug && console.log('e02-2');
                Event.$emit('va.request', {type: 'catnav', category: this.getPath()});
            }
        }
    },

    watch: {
        init(n) {
            this.debug && console.log('e03');
            n && this.categoryInit && Event.$emit('va.request', {type: 'init', context: this.getPath()});
        },

        categoryInit(n) {
            this.debug && console.log('e04');
            if(this.isTokenValid()) {
                this.debug && console.log('e04-1');
                n && this.init && Event.$emit('va.request', {type: 'catnav', category: this.getPath()});
            }
            else {
                this.debug && console.log('e04-2');
                n && this.init && Event.$emit('va.request', {type: 'init', context: this.getPath()});
            }
        },

        /*
        getCurrentCategoryName(n) {
            n && (this.currentCategory = n);
        },
        */

        $route() {
            this.debug && console.log('e05');
            //Event.$emit('va.request', {type: 'catnav', category: store.state.catDestination});
            Event.$emit('va.request', {type: 'catnav', category: this.getPath()});
        },
    },

    methods: {

        isTokenValid() {
            let valid = false;
            let session = Math.floor((Date.now() - sessionStorage.timeout) / 60000) <= 10 ? true : false;
            
            if(session && this.token.length > 0) {
                valid = true;
            }

            return valid;
        },

        isSessionValid() {
            let valid = false;
            //let session = Math.floor((Date.now() - sessionStorage.timeout) / 60000) <= 10 ? true : false;
   
            if(this.isTokenValid()) {
                valid = true;
            }
            else {
                this.debug && console.log('e01');
                Event.$emit('va.init', {init: 1});
            }

            return valid;
        },

        validSession() {
            let offset = Math.floor((Date.now() - sessionStorage.timeout) / 60000);
            let session = offset <= 10 ? true : false;
            return session;
        },

        getPanelData(lvl) {
            let c1 = this.cat1;
            let c2 = this.cat2;
            let c3 = this.cat3;

            c1 = c1.replace(/-/g, ' ');
            (typeof c2 !== 'undefined') && (c2 = c2.replace(/-/g, ' '));
            (typeof c3 !== 'undefined') && (c3 = c3.replace(/-/g, ' '));

            let level = lvl;
            let categoryList = [];

            for(let key0 in store.state.categories) {
                for(let key1 in store.state.categories[key0]) {
                    if(key1.toLowerCase() == c1) {
                        categoryList = store.state.categories[key0][key1];

                        if(level > 1) {
                            for(let key2 in store.state.categories[key0][key1]) {
                                for(let key3 in store.state.categories[key0][key1][key2]) {
                                    if(key3.toLowerCase() == c2) {
                                        categoryList = store.state.categories[key0][key1][key2][key3];
                                    }
                                }
                            }
                        }

                    }
                }
            }
            return categoryList;
        },

        getPath() {
            var path = 'Root';

            let c1 = typeof this.cat1 !== 'undefined' ? true: false;
            let c2 = typeof this.cat2 !== 'undefined' ? true: false;
            let c3 = typeof this.cat3 !== 'undefined' ? true: false;

            if(c1) {
                var c1Name = this.cat1.replace(/-/g, ' ');
            }

            if(c2) {
                var c2Name = this.cat2.replace(/-/g, ' ');
            }

            if(c3) {
                var c3Name = this.cat3.replace(/-/g, ' ');
            }

            if(c1) {
                for(let key1 in store.state.categories) {
                    for(let key2 in store.state.categories[key1]) {
                        (key2.toLowerCase() == c1Name) && (path = path + '.' + key2);
                        if(c2) {
                            for(let key3 in store.state.categories[key1][key2]) {
                                for(let key4 in store.state.categories[key1][key2][key3]) {
                                    (key4.toLowerCase() == c2Name) && (path = path + '.' + key4);
                                    if(c3) {
                                        for(let key5 in store.state.categories[key1][key2][key3][key4]) {
                                            for(let key6 in store.state.categories[key1][key2][key3][key4][key5]) {
                                                (key6.toLowerCase() == c3Name) && (path = path + '.' + key6);
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                    }
                }
            }

            let valid = path.split('.').length;
            if((path === 'Root') || (c2 && valid == 2) || (c3 && valid == 3)) {
                router.push({path: '/404'});
            }

            return path;
        },

        scrollToHash(){
            let hash = this.hash.replace("#", "")
            let hashElement = document.getElementById(hash)
            if(hashElement)
                hashElement.scrollIntoView();
        }
    },
}
</script>

<style scoped>
    .va-category {
        background: #f9f9f9;
    }

    .wrapper {
        min-height: calc(100vh - 240px);
        position: relative;
        max-width: 930px;
        margin: 0 auto;
        padding-top: 15px;
    }

    h2 {
        font-size: 2.5em;
        font-weight: normal;
        color: #000000;
        padding: 0 0 16px 0;
        margin: 0;
    }

    .category-panels, .category-faq-panels {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .category-faq-panels {
        clear: both;
    }

    @media only screen and (max-width: 966px) {
        .va-category {
            padding: 0 18px 0 18px;
        }
    }
    /*
    @media only screen and (max-width: 650px) {
        .va-category {
            padding: 0 18px 0 18px;
        }
    }
    */

</style>
