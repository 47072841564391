<template>
    <div class="panel" :class="[panelClass + view]">
        <h4>{{categoryName}}</h4>
        <ul>
            <template v-for="items in categoryList">
                <li v-for="(item, index) in items" :key="index">
                    <a :href="'/' + titleToLink(parent) + '/' + titleToLink(categoryName) + '/' + titleToLink(index)" :title="index" @click.prevent="goTo(categoryName + '.' + index)">{{index}}</a>
                </li>
            </template>
        </ul>

        <template v-if="sectionFaqs.length > 0">
            <h6>In this section</h6>
            <ul>
                <li v-for="(item, index) in sectionFaqs" :key="index">
                    <a :href="'/' + item.path + '/article/' + titleToLink(item.faqdisplay)" :title="item.faqdisplay" :data-recid="item.recognition_id" :data-answerid="item.answer_id" @click.prevent="submitRequest(item)">{{item.faqdisplay}}</a>
                </li>
            </ul>
        </template>

        <button class="more" @click="goTo(categoryName)">See more</button>
    </div>
</template>

<script>
import store from '../store';
import router from '../router';

export default {
    name: 'ViewCategoryPagePanel',
    props: ['catdata', 'view', 'parent'],
    data() {
        return {
            panelClass: 'view'
        }
    },
    
    computed: {
        categoryName() {
            let name = '';
            for(let key in this.catdata) {
                name = key;
            }
            return name;
        },

        categoryList() {
            let list = [];
            for(let key in this.catdata) {
                list = this.catdata[key];
            }
            return list;
        },



        sectionFaqs() {
            let faqList = [];
            let engineFaqs = store.state.responseData.data.questions;
            let maincat = store.state.responseData.data.maincat;
            
            for(let key in engineFaqs) {
                //if(engineFaqs[key].category.indexOf(maincat + '.' + this.categoryName) !== -1) {
                if(engineFaqs[key].category == maincat + '.' + this.categoryName) {
                    faqList.push(engineFaqs[key]);
                }
                else {
                    for(let subkey in engineFaqs[key].sub_category) {
                        //if(engineFaqs[key].sub_category[subkey].indexOf(maincat + '.' + this.categoryName) !== -1) {
                        if(engineFaqs[key].sub_category[subkey] == maincat + '.' + this.categoryName) {
                            faqList.push(engineFaqs[key]);
                            break;
                        }                   
                    }
                }
            }
            return faqList;
        }
    },

    methods: {
        goTo(path) {
            store.dispatch('setCatDestination', store.state.responseData.data.maincat + '.' + path);
            let url = store.state.responseData.data.maincat + '.' + path;
            url = url.replace('Root.', '');
            url = url.toLowerCase();
            url = url.replace(/\./g, '/');
            url = url.replace(/ /g, '-');
            router.push({path: '/' + url});
        },

        submitRequest(faq) {
            let title = faq.faqdisplay;
            title = title.toLowerCase();
            title = title.replace(/ /g, '-');
            title = title.replace(/\?/gi, '');

            store.dispatch('setFaqDestinationId', faq.answer_id);
            router.push({path: '/' + faq.path + '/article/' + title});
        },

        titleToLink(title) {
            let link = title;
            link = link.toLowerCase();
            link = link.replace(/ /g, '-');
            link = link.replace(/\?/gi, '');
            return link;
        }
    },
}
</script>

<style scoped>
    .panel {
        /*width: 70%;*/
        width: calc(50% - 15px);
        padding: 24px 24px 84px 24px;
        background: #ffffff;
        margin: 0 0 30px 0;
        box-shadow: 0 0 5px 0 rgba(173,173,173,0.5);
        position: relative;
    }

    .panel.view1 {
        /*width: 27%;*/
        width: calc(50% - 15px);
    }

    .panel.view2 {
        width: 100%;
    }

    .panel.view3, .panel.view4 {
        width: calc(50% - 15px);
    }

    .panel.view5 {
        /*width: 70%;*/
        width: calc(50% - 15px);
    }

    .panel.view6 {
        /*width: 27%;*/
        width: calc(50% - 15px);
    }

    .panel.view7 {
        width: 100%;
    }

    .panel.view8, .panel.view9 {
        width: calc(50% - 15px);
    }




    .panel.view2 ul, .panel.view7 ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .panel.view2 ul li, .panel.view7 ul li {
        width: 50%;
    }




    .panel h4 {
        color: #000000;
        font-size: 1.5em;
        border-bottom: 1px solid #BFBFBF;
        padding: 0 0 15px 0;
        margin: 0 0 15px 0;
        font-weight: normal;
    }

    .panel h6 {
        color: #000000;
        font-size: 1.25em;
        margin: 15px 0 15px 0;
        font-weight: normal;      
    }

    .panel ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 1.125em;
    }

    /*
    .panel ul li:last-child {
        margin: 0 0 78px 0;
    }
    */

    .panel ul li a {
        padding: 0 0 10px 0;
        display: block;
        position: relative;
    }

    .panel ul li a:after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url('./../assets/images/ChevronRight.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 0 -1.5px 4px;
    }

    button {
        display: block;
        border: 1px solid #000000;
        border-radius: var(--border-default);
        background-color: #000000;
        padding: 12px 18px;
        font-size: 1.125em;
        color: #FFFFFF;
        transition: all .2s;
        
        position: absolute;
        bottom: 24px;
        left: 24px;
    }

    button:hover {
        cursor: pointer;
        background: var(--charcoal);
        color: #ffffff;
    }

    @media only screen and (max-width: 650px) {
        .panel {
            width: 100% !important;
        }
    }
    
</style>
