<template>
    <div class="search">
        <div class="wrapper">
            <h2>Commercial Banking Online Help Centre</h2>
            <!--<p>How can we help you?</p>-->
            <div class="va-inp">
                <div class="va-inp-in">
                    <div class="va-txt-pod">
                        <svg data-v-7746971c="" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g data-v-7746971c="" id="Wrapper (Required)"><path data-v-7746971c="" id="Union (Required)" d="M14.7199 13.0667L11.82 10.1667C11.62 9.96667 11.56 9.65334 11.6933 9.4C12.2733 8.30667 12.5 7 12.2 5.62C11.7467 3.50667 10.02 1.82667 7.89329 1.43334C4.02662 0.713337 0.706658 4.03334 1.43332 7.9C1.83332 10.02 3.51327 11.7467 5.6266 12.2067C7.0066 12.5 8.3133 12.28 9.40663 11.7C9.65996 11.5667 9.97331 11.62 10.1733 11.8267L13.0733 14.7267C13.3333 14.9867 13.7533 14.9867 14.0133 14.7267L14.7199 14.02C14.9799 13.76 14.9799 13.34 14.7199 13.08V13.0667ZM2.66664 6.83334C2.66664 4.53334 4.53331 2.66667 6.83331 2.66667C9.13331 2.66667 11 4.53334 11 6.83334C11 9.13334 9.13331 11 6.83331 11C4.53331 11 2.66664 9.13334 2.66664 6.83334Z" fill="black"></path></g></svg>

                        <input type="text" placeholder="How can we help you?" class="va-inp-txt" id="entry" name="entry" tabindex="0" autocomplete="off" @input="submitAutocompleteQuestion" v-on:keyup.enter="submitQuestion" v-model="question" v-on:keyup.down="nextItem" v-on:keyup.up="prevItem" data-tealium-id="mainsearch" data-tealium-tracking="none" aria-label="Please enter your search query"/>

                        <template v-if="this.question != '' && this.showAutocomplete">
                            <single-page-search-autocomplete :key="'s2'" :query="question" :autocomplete="adata" :count="counter" :origin="componentOrigin"></single-page-search-autocomplete>
                        </template>
                    </div>
                    <div class="va-btn-pod">
                        <button type="submit" name="send" class="va-inp-btn" tabindex="0" @click.prevent="submitQuestion" data-tealium-tracking="full" data-tealium-value="$mainsearch" data-tealium-narrative="Main Search">Search</button>
                    </div>
                </div>
                <!--<div class="va-autocomplete"></div>-->
            </div>
        </div>
    </div>    
</template>

<script>
import {Event} from '../event';
import router from '../router';
import store from '../store';

import SinglePageSearchAutocomplete from '@/components/SinglePageSearchAutocomplete';

export default {
    name: 'SinglePageSearch',
    components: {
        SinglePageSearchAutocomplete,
    },
    data() {
        return {
            question: '',
            timer: null,
            interval: null,
            counter: -1,
            componentOrigin: 'homepage',
            debug: false,
            adata: {},
            showAutocomplete: false
        }
    },

    methods: {
        resetAutocomplete: function() {
            this.counter = -1;
            this.showAutocomplete = false;
            store.dispatch('setAutocompleteResponseData', null);
        },

        submitQuestion: function() {
            if(!this.question) {
                return false;
            }
            else {
                let entry = this.question;
                this.question = '';
                if(this.counter != -1) {
                    entry = this.autocompleteData.AutoComplete[this.counter].QuestionText;
                }

                this.resetAutocomplete();
                if(!this.isAutocompleteRequestFinished) {
                    this.debug && console.log('[AUTOCOMPLETE] Autocomplete request not finished, setting interval to wait');
                    this.interval = setInterval(() => {
                        if(this.isAutocompleteRequestFinished) {
                            this.debug && console.log('[AUTOCOMPLETE] Autocomplete request finished, submitting normal request');
                            router.push({ path: 'search', query: {q: entry, rf: 'HomePage'}});
                            clearInterval(this.interval);
                            this.interval = null;
                        }
                    }, 100);
                }
                else {
                    router.push({ path: 'search', query: {q: entry, rf: 'HomePage'}});
                }
            }
        },

        submitAutocompleteQuestion: function() {
            if(this.isAutocompleteRequestFinished) {
                if(this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    if(this.question.length > 2) {
                        //Event.$emit('va.autocomplete', {entry: this.question});
                        Event.$emit('va.request', {type: 'autocomplete', question: this.question});
                        this.showAutocomplete = this.autocompleteData && this.autocompleteData.AutoComplete.length > 0;
                    }
                    else {
                        this.resetAutocomplete(); 
                    }
                }, 100);
            }
        },

        nextItem: function(event) {
            if(this.counter < this.autocompleteData.AutoComplete.length - 1) {
                this.counter++;
            }
            else {
                this.counter = 0;
            }
        },

        prevItem: function(event) {
            if(this.counter < this.autocompleteData.AutoComplete.length && this.counter != -1 && this.counter != 0) {
                this.counter--;
            }
            else {
                this.counter = this.autocompleteData.AutoComplete.length - 1;    
            }
        },

        handleClickOutside: function(e) {
            if(!document.getElementsByClassName('va-inp-in')[1].contains(e.target)) {
                this.resetAutocomplete();
            }
        },

        handleEscPress: function(e) {
            if(e.keyCode == 27) {
                this.resetAutocomplete();
            }
        }
    },

    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keyup', this.handleEscPress);

    },

    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('keyup', this.handleEscPress);
    },

    beforeRouteLeave(to, from, next) {
        this.question = '';
        this.counter = -1;
        next();
    },

    computed: {
        autocompleteData() {
            return store.state.autocompleteResponseData;
        },

        isAutocompleteRequestFinished() {
            return store.state.isAutocompleteAjaxDone;
        },
    },

    watch: {
        autocompleteData(n) {
            this.adata = n;
        }
    }

}
</script>

<style scoped>

    .search {
        background: #F1F1F1;
    }

    .wrapper {
        max-width: 930px;
        margin: 0 auto;
        padding: 55px 0 25px 0;
    }

    h2 {
        font-size: 2.5em;
        color: #000000; 
        text-align: center;
        font-weight: normal;
        margin: 0;
        padding: 0 0 0 0;
        line-height: 40px;
    }

    p {
        font-family: 'GT Ultra Standard Lloyds Regular', Arial, Helvetica, sans-serif;
        font-size: 1.375em;
        font-weight: normal;
        text-align: center;
        color: #000000;
        margin: 10px 0 0 0;
    }


    .va-inp {
        margin: 55px auto 0 auto;
        width: 540px;
    }

    .va-inp * {
        font-family: 'GT Ultra Standard Lloyds Regular', Arial, Helvetica, sans-serif;
    }

    .va-inp-in {
        position: relative;
    }

    /*
    .va-inp-in:focus {
    }
    */

    .va-txt-pod {
        margin-right: 140px;
        position: relative;
    }

    .va-txt-pod svg {
        position: absolute;
        top: 15px;
        left: 16px;
    }

    .va-inp-txt {
        border: 1px solid #000000;
        border-radius: var(--border-default);
        line-height: 48px;
        background: none;
        height: 48px;
        outline: none;
        color: #000000;
        box-shadow: none;
        background: #ffffff;
        width: 100%;
        padding: 0 17px 0 17px;
        font-size: 1.125em;
        padding-left: 36px;
    }

    .va-btn-pod * {
        font-family: 'GT Ultra Standard Lloyds Bold', Arial, Helvetica, sans-serif;
    }

    .va-btn-pod {
        position:absolute;
        right:0;
        top:0;
    }

    .va-inp-btn {
        display: inline-block;
        width: 120px;
        height: 48px;
        background: #000000;
        color: #ffffff;
        text-align: left;
        text-decoration: none;
        line-height: 48px;
        transition: all 0.5s;
        border: none;
        position: relative;
        padding: 0 0 0 18px;
        font-size: 1.125em;
        border-radius: var(--border-default);
        border: solid 1px #000000;
    }

    .va-inp-btn:after {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: block;
        height: 6px;
        right: 26px;
        position: absolute;
        width: 6px;
        top: 50%;
        margin: auto;
        transform: translateY(-50%) rotate(45deg);
        color: #ffffff;
    }

    .va-inp-btn:hover {
        cursor: pointer;
        color: #000000;
        background: #ffffff;
        border: solid 1px #000000;
        text-decoration: underline 2px;
    }

    .va-inp-btn:hover:after {
        color: #000000;
    }

    @media only screen and (max-width: 650px) {
        .va-inp {
            width: 100%;
            padding: 0 18px 0 18px;
        }

        .va-txt-pod {
            margin: 0 0 14px 0;
        }

        .va-btn-pod {
            position: static;
        }

        .va-inp-btn {
            width: 100%;
            text-align: center;
        }
    }


</style>

